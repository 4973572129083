<template>
  <div>
    <b-row>
      <b-col class="col-lg-2 hidden-xs"></b-col>
      <b-col class="col-lg-8 col-md-12 col-sm-12">
        <b-card v-if="order">
          <b-row class="section_1 direction">
            <b-col class="col-lg-4 col-md-4 col-sm-12 d-flex justify-content-start">
              <img class="logo" :src="require(`@/assets/images/logo_2.svg`)"/>
            </b-col>
            <b-col class="col-lg-4 col-md-4 col-sm-12 d-flex justify-content-center">
              <div class="section_1__line_red">
                <h2>ORDER DETAILS</h2>
              </div>
            </b-col>
            <b-col class="col-lg-4 col-md-4 col-sm-12 d-flex justify-content-end sm_center">
              <h5>ORDER # {{ order.orderId }}</h5>
            </b-col>
          </b-row>

          <b-row class="section_2 direction">
            <b-col>
              <b-row>
                <b-col class="d-flex justify-content-center">
                  <h6 class="section_2__title">ORDER INFORMATION</h6>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-row>
                    <b-col cols="4"><h6>Order nr: </h6></b-col>
                    <b-col><span>{{ order.orderId }}</span></b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="4"><h6>Order Date: </h6></b-col>
                    <b-col><span>{{ order.createdAt | localeDate }}</span></b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="4"><h6>Order Type: </h6></b-col>
                    <b-col><span>{{ order.type }}</span></b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="4"><h6>Customer name: </h6></b-col>
                    <b-col><span>{{ description.description.client.name }}</span></b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="4"><h6>Customer email: </h6></b-col>
                    <b-col><span>{{ description.description.client.email }}</span></b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="4"><h6>Dealer name: </h6></b-col>
                    <b-col><span>{{ company.nameCompany }}</span></b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="4"><h6>Dealer email: </h6></b-col>
                    <b-col><span>{{ company.emailCompany }}</span></b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
            <b-col>
              <b-row>
                <b-col class="d-flex justify-content-center">
                  <h6 class="section_2__title">VEHICLE INFORMATION</h6>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-row>
                    <b-col cols="4"><h6>VIN:</h6></b-col>
                    <b-col><span>{{ order.VIN.toUpperCase() }}</span></b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="4"><h6>Make:</h6></b-col>
                    <b-col><span>{{ vehicle.brand }}</span></b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="4"><h6>Model:</h6></b-col>
                    <b-col><span>{{ vehicle.model }}</span></b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="4"><h6>Gen:</h6></b-col>
                    <b-col>{{ vehicle.generation }}</b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="4"><h6>Engine code:</h6></b-col>
                    <b-col><span>{{ vehicle.engine }}</span></b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="4"><h6>Gearbox code:</h6></b-col>
                    <b-col><span>{{ vehicle|showDsg }}</span></b-col>
                  </b-row>

                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <hr>
          <!--    **************************************************   ECU OPTIONS-->
          <b-row class="section_3 direction">
            <b-col>
              <b-row>
                <b-col class="d-flex justify-content-center align-items-start">
                  <img class="icons mr-1" :src="require(`@/assets/images/hardware.svg`)"/>
                  <h6 class="section_3__title">HARDWARE</h6>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-row>
                    <b-col><h6>REQUIRED MODIFICATIONS</h6></b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <ul>
                        <li v-for="(item, index) in arrayItemsHardwareMandatory" :key="index">{{ item.name }}
                          <span v-if="item.options.length>0">{{ item.options[0].description }}</span>
                        </li>
                      </ul>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col>
                  <b-row>
                    <b-col><h6>ADDITIONAL MODIFICATIONS</h6></b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <ul>
                        <li v-for="(item, index) in arrayItemsHardware" :key="index">{{ item.name }}
                          <span v-if="item.options.length>0">{{ item.options[0].description }}</span>
                        </li>
                      </ul>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
            <b-col>
              <b-row>
                <b-col class="d-flex justify-content-center align-items-start">
                  <img class="icons mr-1" :src="require(`@/assets/images/software.svg`)"/>
                  <h6 class="section_3__title">SOFTWARE</h6>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-row>
                    <b-col><h6>FEATURES</h6></b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <ul>
                        <li v-for="(item, index) in arrayItemsSoftwareMandatory" :key="index">{{ item.name }}
                          <span v-if="item.options.length>0">{{ item.options[0].description }}</span>
                        </li>
                      </ul>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col>
                  <b-row>
                    <b-col><h6>SELECTABLE OPTIONS</h6></b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <ul>
                        <li v-for="(item, index) in arrayItemsSoftware" :key="index">{{ item.name }}
                          <span v-if="item.options.length>0">{{ item.options[0].description }}</span>
                        </li>
                      </ul>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

            </b-col>
          </b-row>
          <hr>
          <!--    **************************************************   DSG OPTIONS-->
          <b-row class="section_4 direction">
            <b-col>
              <b-row>
                <b-col class="d-flex justify-content-center align-items-start">
                  <img class="icons mr-1" :src="require(`@/assets/images/hardware.svg`)"/>
                  <h6 class="section_4__title">HARDWARE</h6>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-row>
                    <b-col><h6>REQUIRED MODIFICATIONS</h6></b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <ul>
                        <li v-for="(item, index) in dsgHardwareXoptions" :key="index">{{ item.name }}
                          <span v-if="item.options.length>0">{{ item.options[0].description }}</span>
                        </li>
                      </ul>
                    </b-col>
                  </b-row>

                </b-col>
                <b-col>
                  <b-row>
                    <b-col><h6>ADDITIONAL MODIFICATIONS</h6></b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <ul>
                        <li v-for="(item, index) in dsgHardwareOptions" :key="index">{{ item.name }}
                          <span v-if="item.options.length>0">{{ item.options[0].description }}</span>
                        </li>
                      </ul>
                    </b-col>
                  </b-row>

                </b-col>
              </b-row>
            </b-col>
            <b-col>
              <b-row>
                <b-col class="d-flex justify-content-center align-items-start">
                  <img class="icons mr-1" :src="require(`@/assets/images/software.svg`)"/>
                  <h6 class="section_4__title">SOFTWARE</h6>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-row>
                    <b-col><h6>FEATURES</h6></b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <ul>
                        <li v-for="(item, index) in arrayItemsPreselectedDsg" :key="index">{{ item.name }}
                          <span v-if="item.options.length>0">{{ item.options[0].description }}</span>
                        </li>
                      </ul>
                    </b-col>
                  </b-row>

                </b-col>
                <b-col>
                  <b-row>
                    <b-col><h6>SELECTABLE OPTIONS</h6></b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <ul>
                        <li v-for="(item, index) in arrayItemsDsg" :key="index">{{ item.name }}
                          <span v-if="item.options.length>0">{{ item.options[0].description }}</span>
                        </li>
                      </ul>
                    </b-col>
                  </b-row>

                </b-col>
              </b-row>

            </b-col>
          </b-row>

          <b-row class="no-print btn-print">
            <b-col class="d-flex justify-content-center">
              <b-button variant="outline-danger" style="color: black; font-weight: bold" @click="print()">PRINT PAGE
              </b-button>
            </b-col>
          </b-row>

        </b-card>
      </b-col>
      <b-col class="col-lg-2 hidden-xs"></b-col>
    </b-row>

    <b-row v-if="!showInfo" style="height:100vh;">
      <b-col class="col-lg-5 hidden-xs"></b-col>
      <b-col class="col-lg-2 col-sm-10">
        <b-card class="no-print btn-print" style="margin-top: 50%">
          <b-card-body>
            <b-row>
              <b-col class="text-center">
                <img class="logo" :src="require(`@/assets/images/logo.svg`)"/>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <label class="" for="f1_Username">Do you want to view order detail?</label>
                <b-input id="f1_Username" v-model="code" class="mb-2 mr-sm-2 mb-sm-0" placeholder="Access Code"/>
                <b-button variant="primary" class="btn-block mt-2" @click="validateCode">Validate Access Code</b-button>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col class="col-lg-5 hidden-xs"></b-col>
    </b-row>

    <b-modal
      id="modal-feedback"
      dialog-class="modal-feedback"
      size="md"
      title="Your feedback is appreciated!"
      header-text-variant="white"
      header-class="modal-title"
      hide-footer="true"
      centered="true"
      v-if="showInfo"
    >
      <b-row>
        <b-col class="text-center">
          <b>Please rate our products and services:</b>
        </b-col>
      </b-row>
      <b-row no-gutters class="text-center mt-2">
        <b-col>
          <div><b>Customer service</b></div>
          <div style="display: flex; justify-content: space-around;">
            <div><b>Poor</b></div>
            <div><b>Excellent</b></div>
          </div>
          <div>
            <b-button-group>
              <b-button class="btn-start" v-for="(item, index) of feedbackList" :key="index" :class="{'selected': item.selected}"
                        @click="setStar(item)"> {{ item.id }}
              </b-button>
            </b-button-group>
          </div>
        </b-col>
      </b-row>
      <b-row no-gutters class="text-center mt-4">
        <b-col>
          <div><b>Vehicle performance</b></div>
          <div style="display: flex; justify-content: space-around;">
            <div><b>Poor</b></div>
            <div><b>Excellent</b></div>
          </div>
          <div>
            <b-button-group>
              <b-button class="btn-start2" v-for="(item, index) of feedbackList2" :key="index" :class="{'selected2': item.selected}"
                        @click="setStarPerformance(item)"> {{ item.id }}
              </b-button>
            </b-button-group>
          </div>
        </b-col>
      </b-row>
      <b-row no-gutters class="text-center mt-4">
        <b-col>
          <b-form-textarea
            id="textarea"
            v-model="opinion"
            placeholder=" Write your review (optional)"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </b-col>
      </b-row>
      <b-row no-gutters class="text-center mt-2 mb-2">
        <b-col>
          <b>Would recommend us?</b>
        </b-col>
      </b-row>
      <b-row no-gutters class="text-center">
        <b-col>
          <b-button-group>
            <b-button class="btn-recomended" id="btn-hand-0" @click="recomended(0)"><i
              class="material-icons">thumb_down</i></b-button>
            <b-button class="btn-recomended" id="btn-hand-1" @click="recomended(1)"><i
              class="material-icons">thumb_up</i></b-button>
          </b-button-group>
        </b-col>
      </b-row>
      <b-row no-gutters class="text-center mt-4">
        <b-col>
          <b-button variant="danger" class="btn-block" @click="send">SEND</b-button>
        </b-col>
      </b-row>
    </b-modal>

  </div>
</template>

<script>
import {get, post} from '@/services/api';
import {getUrgencyName} from "@/utils";

export default {
  name: 'OrderSoftwareCustomer',
  data: () => ({
    description: null,
    vehicle: null,
    order: null,
    company: null,
    arrayItemsSoftware: [],
    arrayItemsHardware: [],
    arrayItemsDsg: [],
    arrayItemsPreselectedDsg: [],
    arrayItemsSoftwareMandatory: [],
    arrayItemsHardwareMandatory: [],
    dsgHardwareOptions: [],
    dsgHardwareXoptions: [],
    inputText: '',
    icon: null,
    feedbackHand: 0,
    feedbackNumber: 0,
    performanceNumber: 0,
    opinion: null,
    showModalFeedback: true,
    feedbackList: [
      {id: 1, value: 'What made your experience so bad', icon: 'sentiment_very_dissatisfied'},
      {id: 2, value: 'What has gone so wrong', icon: 'sentiment_dissatisfied'},
      {id: 3, value: 'What would you recommend us improve?', icon: 'sentiment_neutral'},
      {id: 4, value: 'We were close. That we lacked?', icon: 'sentiment_satisfied'},
      {id: 5, value: '', icon: 'sentiment_very_satisfied'},
    ],
    feedbackList2: [
      {id: 1, value: 'What made your experience so bad', icon: 'sentiment_very_dissatisfied'},
      {id: 2, value: 'What has gone so wrong', icon: 'sentiment_dissatisfied'},
      {id: 3, value: 'What would you recommend us improve?', icon: 'sentiment_neutral'},
      {id: 4, value: 'We were close. That we lacked?', icon: 'sentiment_satisfied'},
      {id: 5, value: '', icon: 'sentiment_very_satisfied'},
    ],
    showButton: true,
    showInfo: false,
    code: null,
    orderId: null,
  }),
  created() {
    this.orderId = this.$route.params.number_order
  },
  methods: {
    validateCode() {
      get(`auth/validate-access-order-software/${this.orderId}/${this.code}`, null, false).then(({data}) => {
        if (data) {
          this.showInfo = true
          this.loadInformation();
        }
      })
    },
    loadInformation() {
      get(`site/order-by-number/${this.orderId}`, null, false).then(({data}) => {
        this.order = data.order
        if (this.order !== undefined && this.order !== null) {
          this.order.urgency = getUrgencyName(this.order.urgency)
          this.company = data.company
          this.showModalFeedback = (data.feedback == null);
          this.description = JSON.parse(data.order.description)
          let items = this.description.item
          let tuned = []
          if(data.tunedFiles){
            data.tunedFiles.forEach(el => {
              tuned = tuned.concat(el.tuned_file_object.visual_options)
            })
          }
          items = items.concat(tuned)
          this.vehicle = JSON.parse(data.order.vehicle)
          this.arrayItemsSoftware = items.filter(x => x.location.toString() === "sofware") // selectable
          this.arrayItemsSoftwareMandatory = items.filter(x => x.location.toString() === "sofwareMandatory")  // features
          this.arrayItemsHardware = items.filter(x => x.location.toString() === "hardware") // additional
          this.arrayItemsHardwareMandatory = items.filter(x => x.location.toString() === "hardwareMandatory")   // hardware mandatory
          this.arrayItemsDsg = items.filter(x => x.location.toString() === "dsgOptions")
          this.arrayItemsPreselectedDsg = items.filter(x => x.location.toString() === "preSelectedDsgOptions")

          this.dsgHardwareOptions = items.filter(x => x.location.toString() === 'dsgHardwareOptions');
          this.dsgHardwareXoptions = items.filter(x => x.location.toString() === 'dsgHardwareXoptions');
          if (this.showModalFeedback) {
            this.$bvModal.show('modal-feedback')
          }
        }
      }).catch()
    },
    setStar(item) {
      this.feedbackList = this.feedbackList.map(el => ({
        ...el,
        selected: el.id === item.id
      }));
      this.icon = item.icon;
      this.show
      this.feedbackNumber = item.id
    },
    setStarPerformance(item) {
      this.feedbackList2 = this.feedbackList2.map(el => ({
        ...el,
        selected: el.id === item.id
      }));
      this.icon = item.icon;
      this.show
      this.performanceNumber = item.id
    },
    recomended(id) {
      this.feedbackHand = id;
      document.getElementById(`btn-hand-${id}`).classList.add('selected')
      const d = (Number(id) === Number(0)) ? 1 : 0
      document.getElementById(`btn-hand-${d}`).classList.remove('selected')
    },
    async send() {
      if (this.feedbackNumber > 0 && this.performanceNumber > 0) {
        await post('site/feedback', {
          numberOrder: this.order.id,
          recomended: this.feedbackHand,
          calification: this.feedbackNumber,
          performance: this.performanceNumber,
          description: this.opinion,
          dealerId: this.order.dealerId,
          nameCompany: (this.company !== undefined) ? this.company.nameCompany : null,
          vin: this.order.VIN
        })
        this.showModalFeedback = false;
        this.$bvModal.hide('modal-feedback')
        this.showAlert('Your feedback is  appreciated! Thank you.', 'success').then()
      } else {
        this.showAlert('Please rate the Customer service and Vehicle performance', 'info').then()
      }
    },
    async showAlert(msg, type) {
      await this.$swal({
        title: (type === 'success') ? 'Success' : 'Error',
        text: msg,
        icon: (type === 'success') ? 'success' : 'error',
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonColor: '#d14343',
      });
    },
    print() {
      window.print()
    },
  },
  watch: {
    '$route.params'() {
      this.loadInformation();
    }
  }
};
</script>
<style scoped lang="scss">
$red: #d14343;
$black: #000000;
body {
  font-family: 'TTnorms', sans-serif !important;
}

h1, h2, h3, h4, h5, h6 {
  color: $black;
  font-weight: bold;
  margin-bottom: 0;
}

ul {
  padding-left: 0;
}

li {
  font-size: 0.8rem;
}

li::marker {
  font-weight: bold;
  font-size: 1.2rem;
}

.logo {
  width: 80%
}

.icons {
  width: 5%;
}

.section_1 {
  margin: 2vw;

  &__line_red {
    border-bottom: $red 1px solid;
  }
}

.section_2 {
  margin: 2vw 2vw 2vw;

  &__title {
    color: $red;
    margin-bottom: 2vw;
  }
}

.section_3 {
  margin: 2vw;

  &__title {
    color: $red;
    margin-bottom: 2vw;
  }
}

.section_4 {
  margin: 2vw;

  &__title {
    color: $red;
    margin-bottom: 2vw;
  }
}

// ============= css modal feedback
.modal-feedback {
  .btn-group {
    b {
      margin: auto;
    }
  }

  .btn-start {
    background: transparent;
    color: black;
    font-size: 2vw;

    &.selected {
      background: black !important;
      color: white !important;
    }

    &:hover {
      background: #d14343;
      color: white;
      transform: scale(0.7);
    }
  }

  .btn-start2 {
    background: transparent;
    color: black;
    font-size: 2vw;

    &.selected2 {
      background: black !important;
      color: white !important;
    }

    &:hover {
      background: #d14343;
      color: white;
      transform: scale(0.7);
    }
  }

  .btn-recomended {
    background: transparent;
    color: black;
    font-size: 2vw;
    margin: 0 1vw;

    &:hover {
      background: #d14343;
      color: white;
      transform: scale(0.7);
    }

    &.selected {
      background: black !important;
      color: white !important;
    }
  }

  .modal-title {
    color: white;
    background: #d14343;
  }

  .icon {
    font-size: 3vw;
    color: black;
  }

  .btn-text {
    margin-left: 0.2vw;
    background: transparent;
    color: black;
    font-weight: bold;
    cursor: none;
    border: 0;
  }
}

@media screen and (max-width: 600px) {
  .hidden-xs {
    display: none;
  }
  .logo {
    width: 100%
  }
  .direction {
    flex-direction: column;

    & > div {
      margin-top: 2vw;
      margin-bottom: 2vw;
    }
  }

  .section_1 {
    &__line_red {
      border-bottom: 0;
    }

    & > div {
      margin-bottom: 5vw;
    }
  }

  .sm_center {
    justify-content: center !important;
  }
  .modal-feedback .btn-start {
    font-size: 6vw;
  }
  .modal-feedback .btn-start2 {
    font-size: 6vw;
  }
  .modal-feedback .btn-recomended {
    font-size: 7vw;
  }
}


@media print {
  .logo {
    width: 100%
  }
  .hidden-xs {
    display: none;
  }
  .btn-print {
    display: none;
  }
  .section_1 {
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: wrap !important;
  }
}

@page {
  margin-top: 2vw !important;
  margin-left: 2vw !important;
  margin-right: 2vw !important;
}
</style>
